import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Date } from "prismic-reactjs";
import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import { device } from "../utils";
import Head from "../components/Head/Head"

const WorkSingle = ({ pageContext }) => {
  const [item, setItem] = useState(null);

  const data = useStaticQuery(graphql`
    query query($uid: String) {
      prismic {
        allWorkcards(uid: $uid) {
          edges {
            node {
              brand
              title
              thumbnail
              description
              date
              categories {
                slug
              }
              url {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              next {
                ... on PRISMIC_Workcard {
                  brand
                  _meta {
                    uid
                  }
                }
              }
              body {
                ... on PRISMIC_WorkcardBodyPhoto1 {
                  fields {
                    photo
                  }
                }
                ... on PRISMIC_WorkcardBodyPhotos2 {
                  fields {
                    photo
                  }
                }
                ... on PRISMIC_WorkcardBodyKeys {
                  type
                  fields {
                    text
                    title1
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setItem(data.prismic.allWorkcards.edges[0].node);
  }, []);

  if (!item) return null;
  return (
    <>
      <PageWrapper>
        <Head title={item.brand[0].text} />
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={item.thumbnail.url} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">{item.categories[0].slug}</Text>
                <Title variant="secSm" className="my-4">
                  {item.title[0].text}{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  {item.description[0].text}
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  {item.brand[0].text}
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Time</Text>
                <Title variant="cardBig" className="mt-3">
                  {Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  }).format(Date(item.date))}
                </Title>
              </Col>
              <Col lg="4">
                <Button
                  arrowRight
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(item.url.url);
                  }}
                >
                  Live work
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            {item.body[0].fields.map((image, index) => (
              <Row>
                <Col xs="12" className="mb-5">
                  <img
                    src={image.photo.url}
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
              </Row>
            ))}
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Key Findings
            </Title>
            <Row>
              {item.body[2].fields.map((key, index) => (
                <Col lg="6" className={`mb-5 p${index % 2 == 0 ? 'r' : 'l'}-lg-5`}>
                  <Title variant="cardBig" className="mb-4">
                    {key.title1[0].text}
                  </Title>
                  <Text variant="p">{key.text[0].text}</Text>
                </Col>
              ))}
            </Row>
          </Container>
        </Section>
        <Section className="mt-lg-5">
          <Container>
            {item.body[1].fields.map((image, index) => (
              <Row>
                <Col xs="12" className="mb-5">
                  <img
                    src={image.photo.url}
                    alt=""
                    className="img-fluid w-100"
                  />
                </Col>
              </Row>
            ))}

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to={"/" + item.next._meta.uid}>
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  {item.next.brand[0].text}
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};

export default WorkSingle;
